<template>
  <v-card height="550px" class="overflow-auto">
    <v-card-title>
      <span class="text-h5">Datenschutzerklärung</span>
    </v-card-title>
    <v-card-text>
      <p>
        Vielen Dank für die Nutzung des Formularsystems des Kantons St.Gallen. Vorliegende Datenschutzerklärung
        erläutert Ihnen als Nutzer des Formularsystems die Art, den Umfang und den Zweck der Erhebung und Verwendung
        personen- und objektbezogener Daten.
      </p>
      <h3>1. Übermittlung und Speicherung der Daten</h3>
      <p>
        Alle Daten werden über eine mit SSL verschlüsselte Verbindung übermittelt.<br>
        Alle Daten für die Generierung des PDFs werden an den Server übermittelt. Gespeichert werden ausschliesslich die
        Angaben zum Objekt (Adresse, Parzellennummer, EGID) sowie die für das Gesuch relevanten Daten (bspw. Zeitpunkt
        des Ersatzes des Heizkessels, Energiebezugsfläche etc.). Persönliche Angaben, wie z.B. Namen, Emailadressen und
        Telefonnummern, werden nicht gespeichert.
      </p>

      <h3>2. Zugriff auf gespeicherte Daten</h3>
      <p>
        Auf die gespeicherten Daten haben ausschliesslich die jeweils verantwortliche Gemeinde Zugriff sowie die
        Mitarbeiter/innen beim Kanton St.Gallen, welche für die kantonalen Prozesse der Gesuche oder Erklärungen
        verantwortlich sind.</p>
      <h3>3. Recht auf Änderung</h3>
      <p>
        Die verantwortlichen Mitarbeiter/innen der zuständigen Gemeinden oder des Kantons St.Gallen behalten sich das
        Recht vor, übermittelte Objektdatensätze zu ergänzen, ändern oder zu löschen.</p>
      <h3>4. Datensicherheit</h3>
      <p>
        Die übermittelten und gespeicherten Daten werden mit verschiedenen technischen und organisatorischen
        Sicherheitsverfahren vor dem unberechtigten Zugriff durch Dritte geschützt. </p>
      <h3>5. Hinweis Öffentlichkeitsprinzip</h3>
      <p>
        Zu beachten ist, dass bei Umweltinformationen das Öffentlichkeitsprinzip gilt (vgl. Art. 10g des
        Umweltschutzgesetzes, SR 814.01). Entsprechende Anfragen um Einsichtsrecht aus der Bürgerschaft sind mit dem Amt
        für Wasser und Energie des Kantons St.Gallen zu koordinieren.</p>
      <h3>6. Infrastruktur</h3>
      <p>
        Für die Datenverarbeitung notwendige Infrastruktur ist die Abraxas Informatik AG verantwortlich. Der
        Serverstandort ist in der Schweiz.
      </p>
      <h3>7. Änderung der Datenschutzerklärung</h3>
      <p>
        Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern oder anzupassen.
      </p>
      <h3>8. Kontakt und Datenverantwortlicher</h3>
      <p>
        Für den Datenschutz verantwortlich ist der Kanton St.Gallen. Bei Fragen zum Datenschutz oder zu weiteren Themen
        im Zusammenhang mit dem Datenschutz, wenden Sie sich bitte an:
        <br/>
        <br/>
        Kanton St.Gallen<br/>
        Baudepartement<br/>
        Amt für Wasser und Energie<br/>
        <a href="mailto:formularsystem@sg.ch">formularsystem@sg.ch</a><br/>
        <br/>
        St.Gallen, 10. Januar 2023 – Datenschutzerklärung Version 1.0
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="$emit('closeDialog')"
      >
        Schliessen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

export default {
  name: 'PrivacyDialog',
  components: {},
  data: () => ({}),
  methods: {},
  computed: {}
}
</script>
<style>

</style>
