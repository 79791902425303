<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <p>
            E-Mail Verifikation: Bevor Sie fortfahren können wird Ihre E-Mail verifiziert.
            Dazu senden wir Ihnen einen Verifikationscode auf nachfolgende E-Mailadresse: {{  email  }}.
            Sollten Sie keine E-Mail erhalten haben, prüfen Sie den Spam-Ordner Ihres Postfachs.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn color="primary"
                 v-if="!verificationSent"
                 :loading="verificationSending"
                 @click="sendVerificationCode()">
            Verfikationscode senden
          </v-btn>
          <v-btn color="primary"
                 v-if="resendAllowed"
                 :loading="verificationSending"
                 @click="sendVerificationCode()">
            Verfikationscode erneut senden
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            :disabled="verificationLocked"
            @input="checkVerification()"
            :loading="verificationChecking"
            v-model="verificationCodeInput"
            v-if="verificationSent"
            :error="verificationFailed"
            :error-messages="verificationErrors"
            label="Verifikationscode eingeben"
            :append-icon="appendedIcon">
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>

import axios from 'axios'

export default {
  name: 'Verification',
  components: {},
  props: ['email'],
  data: () => ({
    verificationSent: false,
    verificationSending: false,
    verificationChecking: false,
    appendedIcon: null,
    verificationFailed: false,
    verificationErrors: [],
    verificationCodeInput: '',
    verificationLocked: false,
    isVerfied: false,
    resendAllowed: false
  }),
  methods: {
    sendVerificationCode () {
      this.verificationSending = true
      axios.post('/verify/create', {
        email: this.email,
        token: this.token
      })
        .then((res) => {
          if (res.status === 200) {
            console.log('verification created')
            this.verificationSent = true
          }
        })
        .finally(() => {
          this.verificationSending = false
          this.removeErrors()
        })
    },
    checkVerification () {
      if (this.verificationCodeInput.length === 6) {
        this.verificationLocked = true
        this.verificationChecking = true
        axios.post('/verify/check', {
          email: this.email,
          token: this.token,
          code: this.verificationCodeInput
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.isVerified === true) {
                // is verified
                this.isVerified = true
                this.appendedIcon = 'mdi-check'
                this.$emit('updateVerified', true)
              } else {
                // verification false
                this.addVerificationFailed('Der eingegebene Code stimmt nicht überein.')
              }
            } else {
              // verification failed
              this.addVerificationFailed('Der eingegebene Code konnte nicht überprüft werden. Versuchen Sie es noch einmal.')
            }
          })
          .finally(() => {
            this.verificationChecking = false
          })
      }
    },
    addVerificationFailed (message) {
      this.isVerified = false
      this.appendedIcon = 'mdi-close'
      this.verificationFailed = true
      this.verificationErrors = [message]
      this.resendAllowed = true
      this.verificationCodeInput = ''
      this.verificationLocked = false
    },
    removeErrors () {
      this.verificationFailed = false
      this.verificationErrors = []
      this.appendedIcon = null
    },
    rand () {
      return Math.random().toString(36).substr(2)
    }
  },
  computed: {
    token: {
      get () {
        if (sessionStorage.getItem('verification_token')) {
          return sessionStorage.getItem('verification_token')
        } else {
          const t = this.rand() + this.rand() + this.rand() + this.rand()
          sessionStorage.setItem('verification_token', t)
          return t
        }
      }
    }
  }
}

</script>
<style>

</style>
